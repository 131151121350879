$(document).ready(function() {
	
	$('.navbar-toggle').on('click', function(){
		$(this).toggleClass('open');
		$('nav.nav-global').toggleClass('visible');
		$('body').toggleClass('freeze');
	})

	// $('.artwork-grid__item').on('hover', function(){
	// 	$(this).find('artwork-title').toggleClass('visible');
	// })

	$('.artwork-grid__item').hover(
	  function() {
	    $(this).find('.artwork-title').addClass('visible');
	  }, function() {
	    $(this).find('.artwork-title').removeClass('visible');
	  }
	);

	// Reaveal artwork title on touch and drag

	$('.artwork-grid__item').on('touchstart', function(){
		$(this).find('.artwork-title').addClass('visible');
	});
		$('.artwork-grid__item').on('touchend', function(){
		$(this).find('.artwork-title').removeClass('visible');
	});

	$('.toggle-lightbox').click(function(e) {
	    //var initialSlideIndex = $(this).attr('data-index');
	    var initialSlideIndex = $(this).data('index');
	    //console.log(initialSlideIndex);
	    $('body').addClass('freeze');
	    $('.lightbox').toggleClass('visible').focus();
	    $('.gallery-carousel-lightbox').slick({
	        //lazyLoad: 'progressive',
	        initialSlide: initialSlideIndex,
	        slidesToShow: 1,
	        fade: true,
	        focusOnChange: true,
	        focusOnSelect: true,
			//mobileFirst: true,
			nextArrow: '<div class="slick-next"><svg viewBox="0 0 103 202" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g style="">  <path d="M1.200000,1.000000 L101.200000,101.000000 L1.200000,201.000000 " style="fill: none; stroke-width: 3.000000px; stroke: rgba(0, 0, 0, 1.000000); " fill="none" stroke="#000000" stroke-width="3.000000" /></g></svg></div>',
			prevArrow: '<div class="slick-prev"><svg viewBox="0 0 103 202" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g style="">  <path d="M102.200000,201.000000 L2.200000,101.000000 L102.200000,1.000000 " style="fill: none; stroke-width: 3.000000px; stroke: rgba(0, 0, 0, 1.000000); " fill="none" stroke="#000000" stroke-width="3.000000" /></g></svg></div>'
	    });
	    $('.slick-slide[data-slick-index="' + initialSlideIndex + '"]').focus();

	});

	$('div.close').on('click', function(){
		$(this).parents('.lightbox').toggleClass('visible');
		// console.log('LOL');
		$('body').removeClass('freeze');
		$('.gallery-carousel-lightbox').slick('unslick');
	})


	$('div.artwork-overview--list ul').each(function() {
	    $(this).hover(function () {
	        $('div.artwork-overview--grid').addClass('greyed-out');
	    },function () {
	        $('div.artwork-overview--grid').removeClass('greyed-out');
	    });
	});

	$('div.artwork-overview--list a').each(function() {
	    var entryID = $(this).attr("data-entry-id");
	    $(this).hover(function () {
	        //console.log(entryID);
	        $('div.artwork-overview--grid').find('a' + "[data-entry-id='" + entryID +"']").addClass('targeted');
	    },function () {
	        $('div.artwork-overview--grid').find('a' + "[data-entry-id='" + entryID +"']").removeClass('targeted');
	    });
	});

	// CAROUSEL

	$('.gallery-carousel').slick({
		fade: true,
		mobileFirst: true,
		nextArrow: '<div class="slick-next"><svg viewBox="0 0 103 202" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g style="">  <path d="M1.200000,1.000000 L101.200000,101.000000 L1.200000,201.000000 " style="fill: none; stroke-width: 3.000000px; stroke: rgba(0, 0, 0, 1.000000); " fill="none" stroke="#000000" stroke-width="3.000000" /></g></svg></div>',
		prevArrow: '<div class="slick-prev"><svg viewBox="0 0 103 202" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g style="">  <path d="M102.200000,201.000000 L2.200000,101.000000 L102.200000,1.000000 " style="fill: none; stroke-width: 3.000000px; stroke: rgba(0, 0, 0, 1.000000); " fill="none" stroke="#000000" stroke-width="3.000000" /></g></svg></div>'
	});

	// TOGGLE ARTWORK INFO VISIBILITY

	$('small.toggle-info').on('click', function(){
		$(this).parents('.gallery__artwork-title').toggleClass('visible');
		console.log('TITLE');
	})
	$('div.gallery__artwork-title div.close-info').on('click', function(){
		$(this).parents('.gallery__artwork-title').toggleClass('visible');
		// console.log('LOL');
		//$('body').removeClass('freeze');
		//$('.gallery-carousel-lightbox').slick('unslick');
	})

	// STICKY NAV

				// grab the initial top offset of the navigation 
   	var stickyNavTop = $('.headerbar').offset().top;
   	var mainContentFromTop = $('main#content').offset().top;
   	
   	
   	// our function that decides weather the navigation bar should have "fixed" css position or not.
   	var stickyNav = function(){
	    var scrollTop = $(window).scrollTop(); // our current vertical position from the top
	         
	    // if we've scrolled more than the navigation, change its position to fixed to stick to top,
	    // otherwise change it back to relative
	    if (scrollTop > stickyNavTop) { 
	        $('.headerbar').addClass('sticky');
	       $('main#content').css('padding-top', mainContentFromTop);
	    } else {
	        $('.headerbar').removeClass('sticky'); 
	        $('main#content').css('padding-top', '0');
	    }
	};

	stickyNav();
	// and run it again every time you scroll
	$(window).scroll(function() {
		stickyNav();
	});

});